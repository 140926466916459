// FAQ

.faq {
    padding: 50px 0;
    &__heading {
        font-size: 48px;
        font-weight: 700;
        font-family: 'PT Sans',Arial,sans-serif;
    }
    &__filters {
        margin-bottom: 20px;
        p {
            font-size: 25px;
            font-family: 'PT Sans',Arial,sans-serif;
            font-weight: 700;
        }
        select {
            max-width: 20%;
        }
    }
    &__wrapper {
        &__button {
            padding: 25px 30px;
            font-family: 'PT Sans',Arial,sans-serif;
            font-weight: 600;
            color: #111;
            background-color: #fff;
            margin-bottom: 2px;
            border: none;
            width: 100%;
            display: flex;
            justify-content: space-between;
            outline: none;
            font-size: 18px;
        }
        &__button:hover, &__button:active, &__button:focus, &__button:focus:not(:focus-visible) {
            color: #111;
            background-color: #fff;
            outline: none;
        }
        &__collapse {
            margin-bottom: 2px;
            border: none !important;
        }
    }
}

.top {
    &__list {
        &__el {
            margin-bottom: 15px;
            &__place {
                font-size: 5em;
                color: #000;
                font-weight: 900;
            }
            &__info {
                margin: 0 0 0 20px;
                &__heading {
                    padding: 0;
                }
                &__heading:hover, &__heading:active, &__heading:focus, &__heading:focus:not(:focus-visible) {
                    background-color: #000000;
                    color: #FFFFFF;
                    outline: none;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
        li:nth-child(1) h1 {
            color: #CFB53B;
        }
        li:nth-child(2) h1 {
            color: #C0C0C0;
        }
        li:nth-child(3) h1 {
            color: #CD7F32;
        }
        li:nth-child(10) h1 {
            font-size: 2.5em;
        }
    }
}
