// Fonts

@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables

@import 'variables';

// Bootstrap

@import '~bootstrap/scss/bootstrap';

.btn-outline-primary:hover {
    color: #FFFFFF!important;
}

header.masthead {
        padding-top: 10.5rem;
        padding-bottom: 6rem;
        text-align: center;
        color: #fff;
        background-image: url("/storage/site/img/fb_header_without_text.jpeg");
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        background-size: cover;
        .masthead-subheading {
            font-size: 1.5rem;
            font-style: italic;
            line-height: 1.5rem;
            margin-bottom: 25px;
            font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        }
        .masthead-heading {
            font-size: 3.25rem;
            font-weight: 700;
            line-height: 3.25rem;
            margin-bottom: 2rem;
            font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        }
    }

@media (min-width: 768px) {
    header.masthead {
            padding-top: 17rem;
            padding-bottom: 12.5rem;
            .masthead-subheading {
                font-size: 2.25rem;
                font-style: italic;
                line-height: 2.25rem;
                margin-bottom: 2rem;
            }
            .masthead-heading {
                font-size: 4.5rem;
                font-weight: 700;
                line-height: 4.5rem;
                margin-bottom: 4rem;
            }
        }
    }

// Animations

@keyframes opacity {
    0% {
        opacity: 1;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
        animation-timing-function: linear;
    }

    100% {
        opacity: 0.5;
        transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
    }
}

.separator-line-horizontal-medium-light {
    width: 36px;
    height: 3px;
}

.bg-theme {
    background: #20609a;
    background: -o-linear-gradient(-45deg,#20609a,#a485fd);
    background: -o-linear-gradient(135deg,#20609a,#a485fd);
    background: linear-gradient(-45deg,#20609a,#a485fd);
}

.margin-5px-tb {
    margin-top: 5px;
    margin-bottom: 5px;
}

.property-style:hover .property-content-props div {
    color: #fff;
}

.property-style:hover .bg-theme {
    background: #fff;
}

.font-weight-600 {
    font-weight: 600;
}

.text-extra-dark-gray {
    color: #232323;
}

.font-size14 {
    font-size: 14px;
    line-height: normal;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

* {
    font-family: 'PT Sans',Arial,sans-serif;
}

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
}

// Imports

@import 'header';

@import 'tariff';

@import 'contactForm';

@import 'partners';

@import 'knowledgeBase';

@import 'international';

@import 'plane';

@import 'platform';

@import 'people';

@import 'banks';

@import 'media';

@import 'faq';

@import 'reviews';

// Media queries

@media only screen and (max-width: 1300px) {
    .banks__image {
        display: none;
    }
    .people__row {
        padding: 0 100px;
    }

    .card__container {
        margin-bottom: 0;
    }

    .tariff__cards__card {
        margin: 0 15px 50px 15px;
        height: auto;
        width: auto;
        padding: 10px;
    }

    .contact-form {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .contact__form-column {
        text-align: center;
        margin: 0 !important;
    }
    .contact-form__list {
        flex-wrap: wrap;
    }
    .contact-form__list__el {
        margin-bottom: 20px;
    }
    .column-1, .column-2 {
        margin-left: 0 !important;
        margin-right: 0 !important;
        text-align: center;
    }
    .column-1 {
        margin-bottom: 20px;
    }

    .contact-form__agreement {
        font-size: 16px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 993px) {
    .inter__img {
        display: none;
    }
    .container__picture {
        display: none;
    }
    .carousel {
        display: none;
    }

    .platform__columns {
        flex-direction: column;
        align-items: center;
    }
    .banks {
        padding: 0 10px;
    }
    .banks__info__heading {
        width: auto;
    }
    .people__heading {
        width: auto;
    }
    .people__row {
        padding: 0 50px;
    }
    .embed-responsive-item {
        width: auto;
    }

    .media__reports__wrapper {
        flex-direction: column;
        margin: 10px;
    }

    .media {
        padding: 0;
    }

    .tariff__cards {
        flex-direction: column;
    }
    .tariff {
        padding: 0;
        text-align: center;
    }
    .tariff__heading {
        font-size: 50px;
    }

    .card__submit {
        margin-bottom: 10px;
    }

    .header__column {
        flex-direction: column;
        padding-bottom: 50px;
    }
    .header__column__heading__first-line {
        font-size: 30px;
        height: 15%;
    }
    .header__column__heading__second-line {
        font-size: 44px;
    }
    .header__column__heading__third-line {
        font-size: 22px;
    }
    .header__column__list__el {
        line-height: 27px;
        font-size: 16px;
        font-weight: 400;
    }
    .header__column__button-wrapper {
        align-items: stretch;
        flex-direction: column;
    }
    .header__column__button-wrapper__paragraph {
        margin: 30px 0 0 15px;
        max-width: 100%;
    }
    .header {
        padding-bottom: 100px;
        flex-direction: column;
    }

    .modal-backdrop.show {
        width: 100%;
        height: 100%;
    }
    .partners {
        padding-left: 20px;
    }
    .pros {
        padding: 0 0 0 30px;
    }

    .knowledge__wrapper {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 0 10%;
    }

    .knowledge__wrapper__column {
        margin-bottom: 30px;
    }

    // сделки

    .inter {
        padding: 50px 0 0 10%;
    }

    // самолёт

    .plane__column__block__img {
        //display: none;
    }

    .plane__heading {
        padding-left: 30px;
        font-size: 36px;
    }
    .plane__subheading {
        padding-left: 30px;
        font-size: 20px;
    }
    .plane__column__block__img {
        max-width: 360px;
        padding-left: 50px;
        margin-bottom: 30px;
    }
    .plane__column__block {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    // banks

    .banks__info__logos {
        justify-content: center;
    }
    .banks__info {
        text-align: center;
    }

    .reviews, .media {
        padding: 0 0 0 30px;
    }
    .faq {
        padding: 50px 0 0 30px;
    }
}

