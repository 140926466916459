// Platform

.platform {
    background: white;
    padding: 50px;

    &__heading {
        margin-bottom: 40px;
        font-size: 42px;
        line-height: 1.23;
        font-weight: 600;
        text-align: center;
    }
    &__subheading {
        font-weight: 300;
        font-size: 22px;
        line-height: 1.5;
        text-align: center;
    }
    &__columns {
        display: flex;
        justify-content: center;
        &__img {
            display: block;
            width: 600px;
            box-sizing: border-box;
            padding: 0 30px 0 30px;
        }
        &__bots {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 150px;
            strong {
                font-weight: 700;
            }
            &__bot-1 {
                max-width: 200px;
                margin-bottom: 50px;
                margin-right: 50px;
                &__heading {
                    &__icon {
                        width: 45px;
                        height: 45px;
                        margin-left: -50px;
                        margin-bottom: -10px;
                    }
                }
            }
            &__bot-2 {
                max-width: 200px;
                margin-bottom: 50px;
                margin-right: 50px;
                text-align: right;
            }
            &__heading {
                font-weight: 600;
                font-size: 20px;
                line-height: 1.35;
                margin-bottom: 10px;
                &__icon {
                    width: 45px;
                    height: 45px;
                    margin-right: -50px;
                    margin-bottom: -10px;
                }
            }
            &__paragraph {
                font-size: 14px;
                line-height: 1.55;
                font-weight: 300;
            }
        }
    }
}
