// Banks

.banks {
    display: flex;
    background: #FFFFFF;
    padding: 50px 150px;
    justify-content: flex-start;
    align-items: flex-start;
    &__image {
        width: 560px;
    }
    &__info {
        &__heading {
            margin-bottom: 20px;
            font-size: 28px;
            line-height: 34px;
            font-weight: 700;
            width: 500px;
        }
        &__paragraph {
            font-size: 18px;
        }
        &__logos {
            display: flex;
            flex-wrap: wrap;
            //flex-direction: column;
            //justify-content: center;
            //align-items: center;
            &__button {
                background-color: #0066cc;
                color: #ffffff;
                -webkit-border-radius: 100px;
                text-decoration: none;
                border: 0 none;
                text-align: center;
                white-space: nowrap;
                font-weight: 700;
                padding: 10px 30px;
                margin-top: 20px;
            }
            &__row {
                display: flex;
                &__block {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    &__logo {
                        width: 143px;
                        margin: 50px 50px 0 0;
                    }
                    &__percentage {
                        color: #888888;
                        font-size: 16px;
                        width: 160px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

#loadMore {
    margin: 0 auto;
}
