// International

.inter {
    display: flex;
    padding: 50px 0;
    &__img {
        width: 700px;
    }
    &__center {
        &__heading {
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 20px;
        }
        &__subheading {
            font-size: 22px;
            line-height: 1.5;
            font-weight: 300;
        }
        &__list {
            &__el {
                font-size: 20px;
                line-height: 1.55;
                font-weight: 300;
                margin-bottom: 20px;
                &__icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
        }
        &__button {
            margin: 0;
            padding: 0;
            color: #0066cc;
            background: none;
            border: none;
            font-size: 18px;
            line-height: 1.55;
            font-weight: 700;
        }
    }
}
