// Media

.media {
    background: #FFFFFF;
    padding: 100px;
    &__heading {
        font-size: 64px;
        font-weight: 700;
        font-family: 'PT Sans',Arial,sans-serif;
    }
    &__logos {
        &__source {
            margin-bottom: 30px;
            &__image {
                border-radius: 20px;
                width: 360px;
            }
            &__image:hover {
                opacity: 0.5;
                animation: opacity 0.2s linear forwards;
            }
        }
    }
    &__reports {
        width: 100%;
        &__heading {
            font-size: 28px;
            font-family: 'PT Sans',Arial,sans-serif;
            font-weight: 700;
        }
        &__wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            &__video {
                height: 250px;
                border: 0;
                width: 100%;
                padding-right: 50px;
            }
        }
    }
}
