// Header

.header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 200px;
    &__logos {
        display: flex;
        &__logo {
            margin-right: 15px;
        }
    }
    &__phone {
        &__number {
            color: #000000;
            font-size: 25px;
            font-weight: 700;
            text-decoration: none;
        }
        &__call {
            background-color: #0066cc;
            color: #ffffff;
            -webkit-border-radius: 100px;
            text-decoration: none;
            border: 0 none;
            text-align: center;
            white-space: nowrap;
            font-weight: 700;
            padding: 10px 30px;
            max-width: 200px;
        }
    }
    &__column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__heading {
            font-weight: 700;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            &__first-line {
                font-size: 50px;
                height: 25%;
            }
            &__second-line {
                font-size: 64px;
            }
            &__third-line {
                font-size: 33px;
            }
        }
        &__list {
            margin-bottom: 40px;
            &__el {
                line-height: 29px;
                font-size: 18px;
                font-weight: 400;
            }
        }
        &__button-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &__button {
                background-color: #0066cc;
                color: #ffffff;
                -webkit-border-radius: 100px;
                text-decoration: none;
                border: 0 none;
                text-align: center;
                white-space: nowrap;
                font-weight: 700;
                padding: 10px 30px;
            }
            &__paragraph {
                color: #888888;
                font-size: 14px;
                margin: 0 0 0 15px;
                max-width: 50%;
            }
        }
        &__anchors {

            &__anchor {
                color: #0066cc;
                font-size: 20px;
                font-family: 'GothamPro',Arial,sans-serif;
                text-align: center;
                border: 1px solid #0066cc;
                border-radius: 25px;
                margin-bottom: 20px;
                padding: 7px 10px;
                line-height: 31px;
                a {
                    text-decoration: none;
                }
            }
        }
    }
}

.franchise_zoltor {
    border-radius: 30px;
    background-color: #fafafa;
    background-position: center center;
    border-color: #fafafa;
    border-style: solid;
}

.franchise_main-heading {
    color: #000000;
    font-size: 64px;
    font-family: 'PT Sans',Arial,sans-serif;
    line-height: 1.55;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.franchise_heading {
    color: #111111;
    font-size: 18px;
    font-family: 'PT Sans',Arial,sans-serif;
    line-height: 1.5;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.franchise_heading-container {
    border-width: 0px;
    border-radius: 38px;
    background-color: #f3f3f3;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    padding: 15px 50px;
}

.possibilities {
    padding: 0 20px;
}

.possibilities_heading {
    padding: 0 0 0 20px;
    color: #111111;
    font-size: 28px;
    font-family: 'PT Sans',Arial,sans-serif;
    line-height: 1.5;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.possibilities_text-normal {
    color: #000000;
    font-size: 18px;
    font-family: 'PT Sans',Arial,sans-serif;
    line-height: 1.95;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.possibilities_text-not-normal {
    color: #cfcfcf;
    font-size: 18px;
    font-family: 'PT Sans',Arial,sans-serif;
    line-height: 1.95;
    font-weight: 400;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}

.zoltor-desc {
    font-family: 'PT Sans',Arial,sans-serif;
    border-style: solid;
    border-color: transparent;
    font-weight: 400;
    &_examples {
        .example {
            width: 45%;
            background-color: #f3f3f3;
            padding: 50px;
            margin-bottom: 50px;
            &_columns {
                .example_column {
                    //color: #888888;
                    font-size: 16px;
                    line-height: 1.7;
                    font-weight: 400;
                }
            }
            &_heading-block_image {
                padding: 0 20px 30px 0;
                height: 90px;
                width: 70px;
            }
            .heading-block {
                line-height: 1;
                &_heading {
                    font-size: 25px;
                    font-weight: 700;
                    //padding-top: 10px;
                }
                &_subheading {
                    font-size: 18px;
                    line-height: 1.55;
                    font-weight: 400;
                }
            }
        }
    }
}

// menu
@media only screen and (max-width: 500px) {
    .navbar-block {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .participant-logo {
        margin: 20px 0;
    }
}

