// Partners

.partners {
    padding: 50px 0;
}

.carousel {
    width: 560px;
    margin-right: 50px;
    &__image {
        display: block;
        max-width:560px;
        max-height:560px;
        width: auto;
        height: auto;
        box-sizing: border-box;
        background-size: cover;
    }
}

.pros {
    font-family: 'PT Sans',Arial,sans-serif;
    line-height: 1.2;
    &__column {
        &__examples {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
        }
        &__paragraph {
            font-size: 28px;
            font-weight: 700;
        }
    }
}

.example {
    width: 250px;
    margin: 20px 50px 0 0;
    &__button {
        padding: 0;
        display: flex;
        align-items: center;
    }
    &__heading {
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;
    }
    &__subheading {
        color: #888888;
        font-size: 16px;
        font-weight: 400;
    }
}

.button {
    border: none;
    background: none;
    &__text {
        color: #0066cc;
        font-size: 18px;
        line-height: 1.55;
        font-weight: 700;
        margin: 0;
    }
    &__icon {
        margin-left: 10px;
    }
}
