// People (speakers & team blocks)

.people {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 135px;
    padding-bottom: 165px;
    &__heading {
        margin-bottom: 40px;
        font-size: 28px;
        line-height: 34px;
        font-weight: 700;
        text-align: center;
        width: 500px;
    }
    &__row {
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding: 0 300px;
        flex-wrap: wrap;
        &__person {
            display: flex;
            flex-direction: column;
            width: 160px;
            text-align: center;
            flex: 1 1 25%;
            align-items: center;
            &__subheading {
                font-size: 16px;
                font-weight: 600;
                line-height: 19px;
                margin-top: 10px;
            }
            &__photo {
                border-radius: 3000px;
                width: 160px;
                margin-bottom: 30px;
            }
            &__name {
                font-weight: 900;
                font-size: 20px;
                line-height: 1.35;
            }
            &__paragraph {
                color: #888888;
                //font-size: 14px;
                //font-weight: 400;
                width: 160px;
                line-height: 20px;
            }
        }
    }
    &__paragraph {
        font-size: 17px;
        line-height: 1.55;
        font-weight: 500;
        width: 380px;
        text-align: center;
        margin: 30px 0;
    }
    &__button {
        background-color: #0066cc;
        color: #ffffff;
        -webkit-border-radius: 100px;
        text-decoration: none;
        border: 0 none;
        text-align: center;
        white-space: nowrap;
        font-weight: 700;
        padding: 20px 50px;
    }
}

#team {
    background-color: #f2f2f2;
}
