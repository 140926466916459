// Contact Form

.contact-form {
    font-family: 'GothamPro',Arial,sans-serif;
    &__column {

    }

    .column-2 {
        font-weight: 300;
        color: #000000;
        &__radio {
            line-height: 1.55;
        }

        &__input {
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 1px solid #e0e0e0;
            font-size: 16px;
            line-height: 1;
            opacity: .8;
            padding: 0 0 10px 0;
        }
        *:focus, *:active {
            outline: none !important;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
}

.column-1 {
    color: #000000;
    &__heading {
        font-weight: 900;
        margin-bottom: 40px;
    }

    &__paragraph {
        font-size: 16px;
        line-height: 1.55;
        &__heading {
            font-weight: 700;
            font-size: 20px;
        }
        &__subheading {
            margin-bottom: 22px;
            font-weight: 300;
        }
    }
}

.navbar-nav .item a {
    color: #333;
    &:hover,
    &:focus {
        opacity: 0.5;
        color: #fff;
    }
}
