// Reviews

.reviews {
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    width: 100%;
    &__heading {
        font-size: 48px;
        align-self: flex-start;
    }
    &__subheading {
        font-size: 18px;
        align-self: flex-start;
    }
    &__button {
        background-color: #0066cc;
        color: #ffffff;
        -webkit-border-radius: 100px;
        text-decoration: none;
        border: 0 none;
        text-align: center;
        white-space: nowrap;
        font-weight: 700;
        padding: 10px 30px;
        margin-top: 20px;
    }
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        &__video {
            -webkit-transition: all 0.15s ease-in-out;
            transition: all 0.15s ease-in-out;
            margin-bottom: 30px;
        }
    }
}
