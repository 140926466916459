// Knowledge Base

.knowledge {
    background-color: #f3f3f3;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    &__button {
        color: #ffffff;
        font-size: 16px;
        line-height: 1.55;
        font-weight: 700;
        border-width: 2px;
        border-radius: 30px;
        background-color: #0066cc;
        background-position: center center;
        border-color: #0066cc;
        border-style: solid;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        padding: 10px 30px;
    }
    &__heading {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
    }
    &__wrapper {
        display: flex;
        justify-content: center;
        &__column {
            display: flex;
            flex-direction: column;
            max-width: 200px;
            margin-right: 20px;
            -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
            -webkit-mask-size: 100% 120%;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: left top, left bottom;
        }
    }
}

.know-wrap-col {
    &__heading {
        font-size: 18px;
        font-weight: 700;
    }
    &__list {
        &__el {
            color: #888888;
            font-size: 16px;
            font-weight: 400;
        }
    }
}
