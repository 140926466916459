// Plane

.plane {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 135px;
    padding-bottom: 165px;
    background-color: #f2f2f2;
    &__heading {
        margin-bottom: 40px;
        font-size: 42px;
        line-height: 1.23;
        font-weight: 600;
    }
    &__subheading {
        font-weight: 300;
        font-size: 22px;
        line-height: 1.5;
        max-width: 560px;
    }
    &__column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        &__block {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 50px 0;
            &__img {
                max-width: 460px;
                padding-left: 100px;
            }
            &__text {
                margin-left: 50px;
                max-width: 400px;
                &__heading {
                    font-size: 28px;
                    line-height: 1.17;
                    font-weight: 600;
                    margin-bottom: 23px;
                }
                &__paragraph {
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.55;
                }
            }
        }
        &__button {
            background-color: #0066cc;
            color: #ffffff;
            -webkit-border-radius: 100px;
            text-decoration: none;
            border: 0 none;
            text-align: center;
            white-space: nowrap;
            font-weight: 700;
            padding: 20px 50px;
        }
    }

}
