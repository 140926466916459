.tariff {
    font-family: 'PT Sans',Arial,sans-serif;
    background: white;
    padding: 100px;
    &__heading {
        color: #000000;
        font-size: 64px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 50px;
        text-align: center;
    }

    &__cards {
        &__card {
            border-radius: 20px;
            padding: 50px;
            width: 45%;
            background-color: #f3f3f3;
            .card {
                &__heading {
                    color: #000000;
                    font-size: 28px;
                    line-height: 1.2;
                    font-weight: 700;
                }
                &__subheading {
                    color: #888888;
                    font-size: 16px;
                    line-height: 1.2;
                    font-weight: 400;
                }
                &__list {
                    margin: 30px 0;
                    line-height: 1.2;
                    &__el {
                        color: #000000;
                        font-size: 17px;
                        padding: 5px 0;
                        font-weight: 400;
                        &__no {
                            color: #888888;
                        }
                    }
                }
                &__price {
                    font-size: 28px;
                    line-height: 1.2;
                    font-weight: 700;
                    margin: 0 0 10px 0;
                }
                &__button {
                    &__info {
                        color: #888888;
                        font-size: 14px;
                        line-height: 1.2;
                        font-weight: 400;
                        width: 40%;
                    }
                }
                &__submit {
                    color: #ffffff;
                    font-size: 18px;
                    line-height: 1.55;
                    font-weight: 400;
                    border-width: 1px;
                    border-radius: 30px;
                    background-color: #0066cc;
                    padding: 10px 0px;
                    width: 50%;
                    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
                }

            }
        }
    }

    &__help {
        margin-top: 40px;
        &__paragraph {
            color: #000000;
            font-size: 18px;
            line-height: 1.3;
            font-weight: 400;
        }
        &__submit {
            margin-top: 10px;
            font-size: 18px;
            line-height: 1.55;
            border-radius: 30px;
            transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        }
    }
}
